export const DEFAULT_PAGE_NUMBER = 1;

export const ITEMS_PER_PAGE = {
  default: 25,
  100: 100,
  50: 50,
  75: 75,
  25: 25,
  10: 10
};

export const PAGINATION_ROWS_PER_PAGE_OPTIONS = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '75', value: '75' },
  { label: '100', value: '100' }
];

export const getPaginationProps = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  itemsPerPage = ITEMS_PER_PAGE.default
}: {
  pageNumber: number | undefined | null;
  itemsPerPage: number | undefined | null;
}) => {
  // Ensure valid numbers with defaults
  const validPageNumber = Math.max(1, Number(pageNumber));
  const validItemsPerPage = Math.max(1, Number(itemsPerPage));

  const offset = (validPageNumber - 1) * validItemsPerPage;
  const limit = validItemsPerPage;

  return { offset, limit };
};
