import { useSearchParams } from 'react-router-dom';

export const useQueryParams = defaultParams => {
  const [searchParams, setSearchParams] = useSearchParams(defaultParams);

  const params = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const updateSearchParams = updatedParams => {
    setSearchParams({
      ...params,
      ...updatedParams
    });
  };

  return { params, updateSearchParams };
};
