import debounce from 'lodash.debounce';
import React from 'react';
import { Loading } from '../components/Loading';
import { ITEMS_PER_PAGE, PAGINATION_ROWS_PER_PAGE_OPTIONS } from '../utils/pagination.ts';

const Table = ({
  columnNames,
  columnBodies,
  isLoading,
  totalCount,
  offset,
  nextOffset,
  noDataTitle,
  pageNumber,
  itemsPerPage = ITEMS_PER_PAGE.default,
  updateSearchParams
}) => {
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const printValue = debounce(value => updateSearchParams({ search: value, pageNumber: 1 }), 500);
  const noData = totalCount === 0;

  const showItemNumbers = () => {
    if (totalCount <= itemsPerPage) {
      return `1-${totalCount}`;
    }

    if (!nextOffset) {
      return `${offset + 1}-${totalCount}`;
    }

    return `${offset + 1}-${offset + itemsPerPage}`;
  };

  const pagesToShow = () => {
    const pageToShow = [];
    for (let i = 1; i <= totalPages; i++) {
      pageToShow.push(i);
    }

    if (totalPages < 3) {
      return pageToShow;
    }
    if (pageNumber === totalPages) {
      return pageToShow.slice(pageNumber - 3, totalPages);
    }
    if (pageNumber + 1 >= totalPages) {
      return pageToShow.slice(pageNumber - 2, totalPages);
    }
    return pageToShow.slice(pageNumber - 1, pageNumber + 2);
  };

  const handleSearchChange = ({ target }) => printValue(target.value);

  const currentPageOption = PAGINATION_ROWS_PER_PAGE_OPTIONS.find(
    option => Number(option.value) === itemsPerPage
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex-1">
      {/* Header Controls */}
      <div className="flex justify-between items-center mb-5">
        {/* Search Box */}
        <div className="w-full max-w-sm min-w-[200px]">
          <div className="flex items-center bg-white rounded-lg border border-slate-200 hover:border-slate-400 transition-colors duration-200">
            <div className="size-9 mr-0.5 flex items-center justify-center">
              <img src="/assets/search.png" className="size-5 opacity-30" alt="Search Icon" />
            </div>
            <input
              type="text"
              className="w-full py-2 pr-4 text-sm text-slate-700 bg-transparent border-none focus:outline-none"
              placeholder="Search"
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {/* Items per page selector */}
        <div className="flex items-center space-x-2">
          <span className="text-sm text-slate-600">Show:</span>
          <select
            value={currentPageOption?.value ?? ''}
            className="py-2 pl-4 pr-8 rounded-3xl border border-slate-200 text-sm font-medium hover:border-slate-400 transition-colors duration-200"
            onChange={option => updateSearchParams({ itemsPerPage: Number(option.target.value) })}
          >
            {PAGINATION_ROWS_PER_PAGE_OPTIONS.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Table Container - Removed overflow and height constraints */}
      <div className="bg-white rounded-lg border border-slate-200">
        <div>
          <table className="min-w-full divide-y divide-slate-200">
            <thead className="bg-slate-50">
              <tr>
                {columnNames.map((name, i) => (
                  <th
                    key={`${name} ${i}`}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase tracking-wider bg-slate-50"
                  >
                    {name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-slate-200">
              {noData ? (
                <tr>
                  <td
                    colSpan={columnNames.length}
                    className="px-6 py-4 whitespace-nowrap text-sm text-slate-500 text-center"
                  >
                    {noDataTitle}
                  </td>
                </tr>
              ) : (
                columnBodies
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {!noData && (
          <div className="flex justify-between items-center px-4 py-3 border-t border-slate-200">
            <div className="text-sm text-slate-500">
              Showing <span className="font-medium">{showItemNumbers()}</span> of{' '}
              <span className="font-medium">{totalCount}</span>
            </div>

            <div className="flex space-x-1">
              <button
                className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200
                                    ${
                                      pageNumber === 1
                                        ? 'text-slate-300 border-slate-200 cursor-not-allowed'
                                        : 'text-slate-700 border-slate-200 hover:border-slate-400'
                                    }`}
                disabled={pageNumber === 1}
                onClick={() => updateSearchParams({ pageNumber: pageNumber - 1 })}
              >
                Prev
              </button>

              {pagesToShow().map(page => (
                <button
                  key={`page-${page}`}
                  className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200
                                        ${
                                          page === pageNumber
                                            ? 'bg-orange-50 border-orange-200 hover:bg-orange-100'
                                            : 'text-slate-700 border-slate-200 hover:border-slate-400'
                                        }`}
                  onClick={() => updateSearchParams({ pageNumber: page })}
                >
                  {page}
                </button>
              ))}

              <button
                className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200
                                    ${
                                      pageNumber === totalPages
                                        ? 'text-slate-300 border-slate-200 cursor-not-allowed'
                                        : 'text-slate-700 border-slate-200 hover:border-slate-400'
                                    }`}
                disabled={pageNumber === totalPages}
                onClick={() => updateSearchParams({ pageNumber: pageNumber + 1 })}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
