import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import React, {useEffect, useState} from 'react';
import RichTextField from './RichTextField.js';
import {DndContext, rectIntersection} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {arrayMove} from '@dnd-kit/sortable';
import {ErrorMessage, Field, useFormikContext} from 'formik';
import {isEmpty} from 'lodash';
import DragAndDropUpload from '../../DragDropQuestion.js';
import {TSubQuestionColumnNew} from './TSubQuestionColumnNew.js';
import {getFieldName, updateIds} from './utils.js';

export const TQuestionNew = ({
                                 fieldName,
                                 examPaper,
                                 question,
                                 isPublished,
                                 deleteQuestion,
                                 index,
                                 disabledStyle,
                                 viewedQuestions,
                                 setViewedQuestions,
                                 setError,
                                 updateViewedQuestions,
                                 handleChange,
                                 copiedRubricItems,
                                 onCopyRubricItems,
                                 onPasteRubricItems

                             }) => {
    const isQuestionFocused = viewedQuestions.has(question.id);
    const {errors, setFieldValue, submitForm, values} = useFormikContext();
    const {isDragging, setNodeRef, attributes, listeners, transform, transition} = useSortable({
        id: question.id,
        animateLayoutChanges: () => false
    });
    const [isImageUploadVisible, setIsImageUploadVisible] = useState(false);

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1
    };

    useEffect(() => {
        if (isDragging) {
            if (isQuestionFocused) {
                setViewedQuestions(prev => new Map(prev.clear()));
            }
        }
    }, [isDragging]);


    const getTaskPos = (id, subQuestions) =>
        subQuestions.findIndex(subQuestion => subQuestion.id === id);

    const handleDragEnd = event => {
        const {active, over} = event;

        if (active.id === over.id) {
            return;
        }

        // Find the question that contains the dragged sub-question
        const question = examPaper.find(q => q.subQuestions.some(sq => sq.id === active.id));

        if (!question) {
            return;
        }

        const originalPos = getTaskPos(active.id, question.subQuestions);
        const newPos = getTaskPos(over.id, question.subQuestions);

        const updatedSubQuestions = arrayMove(question.subQuestions, originalPos, newPos);

        // Update the subQuestions for the specific question
        const newExamPaper = examPaper.map(q => {
            if (q.id === question.id) {
                return {
                    ...q,
                    subQuestions: updateIds(updatedSubQuestions)
                };
            }

            return q;
        });
        handleChange({value: newExamPaper, fieldName: 'examPaper'});
    };

    const handleImageUpload = async event => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const imageUrl = reader.result;
                setFieldValue(getFieldName(index, 'imageUrl', fieldName), imageUrl);
                setFieldValue(getFieldName(index, 'imageS3Key', fieldName), null);
                setFieldValue(getFieldName(index, 'imageSize', fieldName), 15);

                const currentDescription = values.examPaper[index].description;
                if (currentDescription?.includes('<IMAGE>')) {
                    const cleanedDescription = currentDescription.replace('<IMAGE>', '').trim();
                    setFieldValue(getFieldName(index, 'description', fieldName), cleanedDescription);
                }

                setTimeout(async () => {
                    await submitForm();
                }, 500);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={`rounded-3xl p-4 mb-4 bg-gray-50 shadow-sm ${disabledStyle}`}
        >
            {isPublished && <div className="relative inset-0 bg-opacity-10 z-10"/>}
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                    <div className="size-4 flex items-center justify-center">
                        <button
                            type="button"
                            disabled={isPublished || examPaper.length < 2}
                            onClick={async () => {
                                await deleteQuestion(index);
                                submitForm();
                            }}
                        >
                            <img src="/assets/trash.png" className="size-4" alt="Delete question"/>
                        </button>
                    </div>
                    <div className="font-bold flex items-center text-base">Question {index + 1}</div>
                    {question.description?.includes('<IMAGE>') && (
                        <div className="text-yellow-600 text-sm">
                            Image Upload Needed
                        </div>
                    )}
                    {question.subQuestions?.some(sq => sq.question?.includes('<IMAGE>')) && (
                        <div className="text-yellow-600 text-sm">
                            Subquestion Image Needed
                        </div>
                    )}
                </div>
                <div className="flex items-center space-x-0">
                    <div className={`size-10 flex items-center justify-center ${disabledStyle} `}>
                        <button
                            type="button"
                            {...(isPublished ? {} : {...listeners, ...attributes})}
                            disabled={isPublished}
                        >
                            <img src="/assets/list.png" className="size-10"/>
                        </button>
                    </div>
                    <div className={`size-10 flex items-center justify-center ${disabledStyle} `}>
                        <button type="button" onClick={() => updateViewedQuestions(question.id)}>
                            <img
                                src={isQuestionFocused ? '/assets/arrow-up.png' : '/assets/arrow-down.png'}
                                className="size-10"
                            />
                        </button>
                    </div>
                </div>
            </div>

            {isQuestionFocused && (
                <>
                    <Field
                        name={getFieldName(index, 'description', fieldName)}
                        component={isPublished ? 'textarea' : RichTextField}
                        placeholder="Description"
                        disabled={isPublished}
                        className="sexy-border rounded-lg mb-4 w-full focus:ring-0 focus:sexy-border focus:rounded-lg h-36 mt-4"
                        onBlur={async (e) => {
                            await submitForm();  // Keep existing validation
                            handleChange({      // Add draft save
                                fieldName: 'examPaper',
                                value: values.examPaper
                            });
                        }}
                    />
                    <span className="text-red-800 text-xs">
                        <ErrorMessage name={getFieldName(index, 'description', fieldName)}/>
                    </span>
                    <div className="flex justify-between mb-4">
                        <div>
                            <div className="flex flex-col w-full mb-2">
                                <button
                                    type="button"
                                    className="flex items-center text-sm"
                                    onClick={() => setIsImageUploadVisible(!isImageUploadVisible)}
                                >
                                    <img
                                        src="/assets/back_arrow.png"
                                        className={`size-5 transform ${isImageUploadVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                        alt="Image upload"
                                    />
                                    Optional: Upload an Image or Drawing
                                </button>
                            </div>
                            {isImageUploadVisible && (
                                <div>
                                    <div className="flex items-center">
                                        {!question.imageS3Key && (
                                            <div className="mr-3">
                                                <DragAndDropUpload
                                                    handleImageUpload={handleImageUpload}
                                                    isPublished={isPublished}
                                                />
                                            </div>
                                        )}
                                        {question.imageS3Key && (
                                            <div className="relative flex items-center">
                                                <img
                                                    src={`${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${question.imageS3Key}`}
                                                    alt="Question Image"
                                                    className="w-20 h-20 object-cover"
                                                />
                                                <button
                                                    type="button"
                                                    disabled={isPublished}
                                                    className="ml-2"
                                                    onClick={() => {
                                                        setFieldValue(getFieldName(index, 'imageUrl', fieldName), null);
                                                        setFieldValue(getFieldName(index, 'imageS3Key', fieldName), null);

                                                        setTimeout(async () => {
                                                            await submitForm();
                                                        }, 500);
                                                    }}
                                                >
                                                    <img src="/assets/trash.png" className="size-4"
                                                         alt="Delete question image"/>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {question.imageS3Key && (
                                        <div className="flex items-center mb-4">
                                            <div className="mr-3 text-sm">Image size:</div>
                                            <div className="input-entry-box font-normal px-2 w-14">
                                                <Field
                                                    name={getFieldName(index, 'imageSize', fieldName)}
                                                    disabled={isPublished}
                                                    type="number"
                                                    min="1"
                                                    max="40"
                                                    className="actual-input text-black"
                                                    onBlur={submitForm}
                                                />
                                            </div>
                                            <div className="ml-2">%</div>
                                        </div>
                                    )}
                                    {question.imageS3Key && (
                                        <div className="flex items-center">
                                            <div className="flex gap-2">
                                                {[
                                                    {label: 'S', value: 5},
                                                    {label: 'M', value: 15},
                                                    {label: 'L', value: 30}
                                                ].map(({label, value}) => (
                                                    <button
                                                        key={label}
                                                        type="button"
                                                        className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 ${
                                                            values.examPaper[index].imageSize === value
                                                                ? 'bg-orange-50 border-orange-200 hover:bg-orange-100'
                                                                : 'text-slate-700 border-slate-200 hover:border-slate-400'
                                                        }`}
                                                        disabled={isPublished}
                                                        onClick={() => {
                                                            setFieldValue(getFieldName(index, 'imageSize', fieldName), value);
                                                            setTimeout(async () => {
                                                                await submitForm();
                                                            }, 500);
                                                        }}
                                                    >
                                                        {label}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        {!isEmpty(viewedQuestions.get(question.id)) ? (
                            <button
                                type="button"
                                className="ml-2 self-end pr-[25px]"
                                onClick={() => {
                                    setViewedQuestions(prev => new Map([...prev, [question.id, []]]));
                                }}
                            >
                                <img src="/assets/hide.png" className="size-6" alt="Hide all"/>
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="ml-2 self-end pr-[25px]"
                                onClick={() => {
                                    const allSubIds = question.subQuestions.map(subq => subq.id);
                                    setViewedQuestions(prev => new Map([...prev, [question.id, allSubIds]]));
                                }}
                            >
                                <img src="/assets/view.png" className="size-6" alt="View all"/>
                            </button>
                        )}
                    </div>
                    <DndContext
                        collisionDetection={rectIntersection}
                        modifiers={[restrictToVerticalAxis]}
                        onDragEnd={handleDragEnd}
                    >
                        <TSubQuestionColumnNew
                            question={question}
                            subQuestions={question.subQuestions}
                            isPublished={isPublished}
                            fieldName={getFieldName(index, 'subQuestions', fieldName)}
                            disabledStyle={disabledStyle}
                            setError={setError}
                            setViewedQuestions={setViewedQuestions}
                            viewedQuestions={viewedQuestions}
                            copiedRubricItems={copiedRubricItems}
                            onCopyRubricItems={onCopyRubricItems}
                            onPasteRubricItems={onPasteRubricItems}
                        />
                    </DndContext>
                </>
            )}
        </div>
    );
};