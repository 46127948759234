import React from 'react';
import TEssay from './TEssay';
import TPsetNew from './TPset/TPsetNew';

const TConstructor = ({
                          assignment_id,
                          updateTrigger,
                          setUpdateTrigger,
                          pullQuestions,
                          setPullQuestions,
                          is_essay,
                          is_published,
                          is_exam,
                          criteria,
                          setCriteria,
                          instructions,
                          setInstructions,
                          setEdited,
                          edited,
                          showRubric,
                          setShowRubric,
                          setSolRecent,
                          solRecent,
                          essayGradingInstructions,
                          setEssayGradingInstructions,
                          generated,
                          setGenerated
                      }) => {
    if (is_essay) {
        return (
            <TEssay
                assignment_id={assignment_id}
                updateTrigger={updateTrigger}
                setUpdateTrigger={setUpdateTrigger}
                criteria={criteria}
                setCriteria={setCriteria}
                instructions={instructions}
                setInstructions={setInstructions}
                isPublished={is_published}
                pullQuestions={pullQuestions}
                setPullQuestions={setPullQuestions}
                gradingInstructions={essayGradingInstructions}
                setGradingInstructions={setEssayGradingInstructions}
                showRubric={showRubric}
                setShowRubric={setShowRubric}
            ></TEssay>
        );
    }
    return (
        <TPsetNew
            assignment_id={assignment_id}
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
            pullQuestions={pullQuestions}
            setPullQuestions={setPullQuestions}
            isPublished={is_published}
            setEdited={setEdited}
            edited={edited}
            setSolRecent={setSolRecent}
            solRecent={solRecent}
            isExam={is_exam}
            setGenerated={setGenerated}
            generated={generated}
        ></TPsetNew>
    );
};

export default TConstructor;
