import { Info } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gradeApi } from '../../api.js';
import { useAuth } from '../../auth/AuthContext';
import ConfirmationModal from '../ConfirmationModal';
import DragAndDropUpload from '../DragDropCSV';

const AddDrop = props => {
  const dispatch = useDispatch();

  const [students, setStudents] = useState([]);
  const { user } = useAuth();
  const [studentEmail, setStudentEmail] = useState('');
  const [error, setError] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [csv, setCsv] = useState(null);
  const [emailFocused, setEmailFocused] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isRosterImportOpen, setIsRosterImportOpen] = useState(false);
  const [hoveredStudent, setHoveredStudent] = useState(null);

  const handleFileChange = file => {
    if (file) {
      setCsv(file);
      setError(''); // Clear any previous errors
    } else {
      setError('No file selected or invalid file type.');
    }
  };

  const handleUpload = async () => {
    if (!csv) {
      setError('Please select a CSV file first.');
      return;
    }

    setIsUploading(true);
    setError('');

    const formData = new FormData();
    formData.append('file', csv);

    try {
      const response = await gradeApi.post({
        path: '/api/upload-canvas-csv/',
        body: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });

      const emailList = response.data.emails;
      await addStudents(emailList);
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Failed to process the CSV file. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const addStudents = async emails => {
    const addStudentPromises = emails.map(async email => {
      try {
        await gradeApi.post({
          path: `/api/courses/${props.class_id}/add/`,
          body: { email: email.trim() },
          config: {
            headers: {
              Authorization: `Bearer ${user.session_token}`
            }
          }
        });
        return { email, success: true };
      } catch (error) {
        console.error(`Error adding student with email ${email}:`, error);
        return { email, success: false };
      }
    });

    const results = await Promise.all(addStudentPromises);

    const successfulAdds = results.filter(result => result.success);
    const failedAdds = results.filter(result => !result.success);

    if (successfulAdds.length > 0) {
      console.log(`Successfully added ${successfulAdds.length} student(s).`);
      getStudents(); // Refresh the student list
    }

    if (failedAdds.length > 0) {
      setError(
        `Failed to add ${failedAdds.length} student(s): ${failedAdds.map(result => result.email).join(', ')}`
      );
    }
  };

  const getStudents = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/courses/${props.class_id}/students/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      const users = response.data.users;
      const userInfo = users.map(user => ({
        name: user.first_name + ' ' + user.last_name,
        id: user.id,
        is_null_user: user.is_null_user,
        email: user.email
      }));
      setStudents(userInfo);
    } catch (error) {
      console.error('Error fetching students:', error);
      setError('Failed to fetch students. Please try again.');
    }
  };

  const truncateEmail = email => {
    if (email.length > 20) {
      return email.slice(0, 20) + '...';
    }
    return email;
  };

  const handleRemove = async id => {
    setSelectedStudentId(id);
    setIsConfirmationModalOpen(true);
  };

  const confirmRemoveStudent = async () => {
    const deleteUser = await dispatch(
      removeStudent({
        classId: props.class_id,
        token: user.session_token,
        studentId: selectedStudentId
      })
    );

    if (deleteUser.error) {
      setError(deleteUser.payload);
    } else {
      getStudents();
    }

    setIsConfirmationModalOpen(false);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (studentEmail.trim() === '') {
      setError('Please enter at least one email address.');
      return;
    }

    const emailArray = studentEmail
      .split(',')
      .map(email => email.trim())
      .filter(email => email !== '');
    await addStudents(emailArray);
    setStudentEmail(''); // Clear the input field
  };

  useEffect(() => {
    getStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleRosterImport = () => {
    setIsRosterImportOpen(!isRosterImportOpen);
  };

  return (
    <div className="add-drop-container min-h-screen flex flex-col bg-gray-50">
      <div className="sticky top-0 bg-gray-50 z-10 p-6 pb-2">
        <form className="mb-4" onSubmit={handleSubmit}>
          <div
            className={`flex items-center input-entry-box bg-white font-normal ${error !== '' ? 'border-red-500' : ''}`}
            onFocus={() => {
              setEmailFocused(true);
              setError('');
            }}
            onBlur={() => setEmailFocused(false)}
          >
            <div className="size-9 mr-0.5 flex items-center justify-center">
              <img
                src={
                  error === ''
                    ? '/assets/authentication/email.png'
                    : '/assets/authentication/red-email.png'
                }
                className={emailFocused || error !== '' ? 'size-5' : 'size-5 opacity-30'}
                alt={error === '' ? 'Email Icon' : 'Problem with Email'}
              />
            </div>
            <input
              type="text"
              value={studentEmail}
              className={`actual-input mr-2.5 ${error !== '' ? 'text-red-500' : ''}`}
              placeholder="Student Email(s)"
              onChange={e => setStudentEmail(e.target.value)}
            />
          </div>
          <div className="flex justify-between items-center mt-4">
            <button
              type="button"
              className="flex items-center text-sm text-gray-700 hover:text-gray-900"
              onClick={e => {
                e.preventDefault();
                toggleRosterImport();
              }}
            >
              <span>Roster Import</span>
              <img
                src="/assets/arrow-up.png"
                alt="Toggle Roster Import"
                className={`w-12 h-12 transition-transform duration-300 ${isRosterImportOpen ? 'rotate-180' : ''}`}
              />
            </button>
            <button
              type="submit"
              className="sexy-button transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow h-9 w-auto px-6 py-2 flex items-center justify-center whitespace-nowrap"
            >
              Add Students
            </button>
          </div>
        </form>

        {isRosterImportOpen && (
          <div className="space-y-2">
            <div className="flex items-center text-xs text-gray-600">
              <span>Instructions</span>
              <a
                href="https://athelp.sfsu.edu/hc/en-us/articles/14014800586387-Download-a-list-of-student-email-addresses-in-Canvas"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-1"
              >
                <Info size={14} />
              </a>
            </div>
            <DragAndDropUpload onFileChange={handleFileChange} />
            <div className="flex justify-end">
              <button
                disabled={isUploading}
                className={`px-9 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleUpload}
              >
                {isUploading ? 'Uploading...' : 'Upload CSV'}
              </button>
            </div>
          </div>
        )}

        {error && <p className="text-red-500 mb-4">{error}</p>}
      </div>

      <div className="flex-1 overflow-y-auto px-6">
        <ul className="student-list list-none">
          {students.map((student, index) => (
            <li
              key={index}
              className="student-item flex justify-between items-center border-b py-2"
            >
              <div
                className="relative cursor-pointer"
                onMouseEnter={() => setHoveredStudent(student)}
                onMouseLeave={() => setHoveredStudent(null)}
              >
                {student.is_null_user ? (
                  <span>
                    {truncateEmail(student.email)}{' '}
                    <span className="italic text-gray-500">(pending)</span>
                  </span>
                ) : (
                  <span>{student.name}</span>
                )}
                {hoveredStudent === student && (
                  <div className="absolute z-10 bg-black text-white p-2 rounded text-sm mt-1">
                    ID: {student.id}
                    <br />
                    Email: {student.email}
                  </div>
                )}
              </div>
              <button className="focus:outline-none" onClick={() => handleRemove(student.id)}>
                <img
                  src="/assets/trash.png"
                  alt="Remove"
                  className="w-5 h-5 z-5 opacity-70 hover:opacity-100"
                />
              </button>
            </li>
          ))}
        </ul>
      </div>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        message="Are you sure you want to remove this student? This action cannot be undone."
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={confirmRemoveStudent}
      />
    </div>
  );
};

export default AddDrop;
