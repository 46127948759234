import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useAuth} from '../../auth/AuthContext';

import {Loading} from '../../components/Loading';
import SideBar from '../../components/SideBar';
import BigStepper from '../../components/Stepper.js';
import BigStepperLine from '../../components/StepperLine.js';
import AnswerUpload from '../../components/Teacher/AnswerUpload';
import ExamUpload from '../../components/Teacher/ExamUpload';
import RenderBatch from '../../components/Teacher/RenderBatch';
import TConstructor from '../../components/Teacher/TConstructor';
import TGrade from '../../components/Teacher/TGrade';
import ParsePDF from '../../components/Teacher/TParsePDF'; // New import for PdfUpload component
import TQuestionGrade from '../../components/Teacher/TQuestionGrade.js';
import TopBar from '../../components/TopBar';
import {useQueryParams} from '../../shared/hooks/use-query-params.tsx';
import {clearAssignment, getAssignmentDetails, getSubmissions} from '../../store/teacherSlice.js';
import {DEFAULT_PAGE_NUMBER, ITEMS_PER_PAGE} from '../../utils/pagination.ts';

const HomePage = () => {
    const dispatch = useDispatch();
    const [essayCriteria, setEssayCriteria] = useState([]);
    const [essayInstructions, setEssayInstructions] = useState('');
    const [showRubric, setShowRubric] = useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [pullQuestions, setPullQuestions] = useState(false);
    const [solRecent, setSolRecent] = useState(false);
    const [essayGradingInstructions, setEssayGradingInstructions] = useState('');
    const [edited, setEdited] = useState(false);
    const [topErrorMessage, setTopErrorMessage] = useState('');
    const [generated, setGenerated] = useState(false);
    const [reviewMode, setReviewMode] = useState('Student');

    const {
        data: submissions,
        isLoading: isSubmissionLoading,
        errorMessage: submissionsError
    } = useSelector(state => state?.teacherReducer?.submissions);

    const {
        data: assignment,
        isLoading: isAssignmentLoading,
        errorMessage: assignmentError
    } = useSelector(state => state?.teacherReducer?.assignment);

    const {user} = useAuth();
    let {assignment_id, class_id} = useParams();
    const [section, setSection] = useState('create');
    const tGradeRef = useRef(null);
    const [showScroll, setShowScroll] = useState(false);

    const {
        params: {search, pageNumber, itemsPerPage},
        updateSearchParams
    } = useQueryParams({
        search: '',
        pageNumber: DEFAULT_PAGE_NUMBER,
        itemsPerPage: ITEMS_PER_PAGE[50]
    });

    const getAllSubmissions = () =>
        dispatch(
            getSubmissions({
                assignmentId: assignment_id,
                token: user.session_token,
                search,
                pageNumber: Number(pageNumber),
                itemsPerPage: Number(itemsPerPage)
            })
        );

    const getAssignment = () =>
        dispatch(
            getAssignmentDetails({
                assignmentId: assignment_id,
                token: user.session_token,
                classId: class_id
            })
        );

    useEffect(() => {
        getAssignment();

        return () => dispatch(clearAssignment());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getAllSubmissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, pageNumber, itemsPerPage, updateTrigger]);

    useEffect(() => {
        if (assignment?.is_assignment_published && !assignment?.is_essay) {
            setSection('review');
        }
    }, [assignment]);

    const handleIntersection = useCallback(entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
            setShowScroll(false);
        } else {
            setShowScroll(true);
        }
    }, []);

    useEffect(() => {
        // Only set up the observer if this is an essay assignment
        if (!assignment?.is_essay) {
            return;
        }
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });

        const currentRef = tGradeRef.current;

        if (!currentRef) {
            const mutationObserver = new MutationObserver(mutations => {
                if (tGradeRef.current) {
                    observer.observe(tGradeRef.current);
                    mutationObserver.disconnect();
                }
            });

            mutationObserver.observe(document.body, {
                childList: true,
                subtree: true
            });

            return () => {
                mutationObserver.disconnect();
                observer.disconnect();
            };
        } else {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
            observer.disconnect();
        };
    }, [handleIntersection, assignment?.is_essay]);

    const scrollToTGrade = () => {
        tGradeRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    const handleSetReviewMode = mode => {
        setReviewMode(mode);
        console.log('Review mode set to:', mode);
    };

    if (isSubmissionLoading || isAssignmentLoading || !assignment) {
        return <Loading/>;
    }

    return assignment.is_essay ? (
        // Original essay layout with sidebar
        <div className="HomePageHeader">
            <div id="Main-container" className="flex flex-row">
                <div id="Left-Sidebar" className="fixed">
                    <SideBar activePage="Home"/>
                </div>
                <div id="Right-content" className="flex ml-side-bar-size pr-[30px] w-full">
                    <TopBar
                        assignment_in={assignment}
                        submissions_in={submissions}
                        setUpdateTrigger={setUpdateTrigger}
                        essayCriteria={essayCriteria}
                        essayInstructions={essayInstructions}
                        setPullQuestions={setPullQuestions}
                        edited={edited}
                        essayGradingInstructions={essayGradingInstructions}
                        showRubric={showRubric}
                        topErrorMessage={topErrorMessage}
                        setTopErrorMessage={setTopErrorMessage}
                        generated={generated}
                        setGenerated={setGenerated}
                    />
                    <div
                        className={`flex flex-col mb-32 w-full ml-[30px] ${topErrorMessage ? 'mt-44' : 'mt-36'}`}
                    >
                        <div className="grid grid-cols-[84px_auto]">
                            <BigStepper stepNumber={0} top={5}/>
                            <ParsePDF
                                assignment_id={assignment_id}
                                setUpdateTrigger={setUpdateTrigger}
                                isPublished={assignment.is_assignment_published}
                                setPullQuestions={setPullQuestions}
                                setEdited={setEdited}
                                setSolRecent={setSolRecent}
                                isEssay={assignment.is_essay}
                            />
                        </div>
                        <BigStepperLine/>
                        <div className="grid grid-cols-[84px_auto]">
                            <BigStepper stepNumber={1} top={5}/>
                            <TConstructor
                                assignment_id={assignment_id}
                                updateTrigger={updateTrigger}
                                setUpdateTrigger={setUpdateTrigger}
                                pullQuestions={pullQuestions}
                                setPullQuestions={setPullQuestions}
                                is_essay={assignment.is_essay}
                                is_published={assignment.is_assignment_published}
                                is_exam={assignment.is_exam}
                                criteria={essayCriteria}
                                setCriteria={setEssayCriteria}
                                instructions={essayInstructions}
                                setInstructions={setEssayInstructions}
                                setEdited={setEdited}
                                edited={edited}
                                showRubric={showRubric}
                                setShowRubric={setShowRubric}
                                setSolRecent={setSolRecent}
                                solRecent={solRecent}
                                essayGradingInstructions={essayGradingInstructions}
                                setEssayGradingInstructions={setEssayGradingInstructions}
                            />
                        </div>
                        <BigStepperLine/>
                        <div ref={tGradeRef} className="grid grid-cols-[84px_auto]">
                            <BigStepper stepNumber={2} top={0} ht={'[50px]'}/>
                            <TGrade
                                reviewMode="Student"
                                isLoading={isSubmissionLoading}
                                pageNumber={pageNumber}
                                itemsPerPage={itemsPerPage}
                                submissionsError={submissionsError}
                                assignment={assignment}
                                submissions={submissions}
                                setUpdateTrigger={setUpdateTrigger}
                                setReviewMode={handleSetReviewMode}
                                updateSearchParams={updateSearchParams}
                            />
                        </div>
                    </div>
                    {showScroll && (
                        <button
                            className="fixed h-[40px] w-[40px] right-[3%] bottom-[5%] z-40
                                rounded-full border-[1px] border-black bg-white"
                            onClick={scrollToTGrade}
                        >
                            <i className="fas fa-chevron-down m-auto"/>
                        </button>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <>
            {section === 'create' ? (
                <div className="HomePageHeader">
                    <div id="Main-container" className="flex flex-row">
                        <div id="Right-content" className="flex w-full">
                            <TopBar
                                assignment_in={assignment}
                                submissions_in={submissions}
                                setUpdateTrigger={setUpdateTrigger}
                                essayCriteria={essayCriteria}
                                essayInstructions={essayInstructions}
                                setPullQuestions={setPullQuestions}
                                edited={edited}
                                essayGradingInstructions={essayGradingInstructions}
                                showRubric={showRubric}
                                topErrorMessage={topErrorMessage}
                                setTopErrorMessage={setTopErrorMessage}
                                section={section}
                                setSection={setSection}
                                generated={generated}
                            />
                            <div
                                className={`flex flex-col mb-32 w-[55%] ml-[15px] mr-0 ${topErrorMessage ? 'mt-34' : 'mt-28'}`}
                            >
                                <>
                                    <div className="flex space-x-4">
                                        <button
                                            className="w-fit border-[1px] rounded-3xl font-semibold text-custom-orange text-small py-2 px-4 shadow-sm transition-all duration-300 flex items-center space-x-2"
                                            onClick={() => window.open('https://youtu.be/kPP4Rbn2YCE', '_blank')}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                            </svg>
                                            <span>Tutorial</span>
                                        </button>
                                        <button
                                            className="w-fit border-[1px] rounded-3xl text-custom-orange  font-semibold text-small py-2 px-4 shadow-sm transition-all duration-300 flex items-center space-x-2"
                                            onClick={() => window.open('https://calendly.com/maxbohun/gradewiz', '_blank')}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M12 9v3m0 0v3m0-3h3m-3 0H9"/>
                                            </svg>
                                            <span>Help</span>
                                        </button>
                                    </div>
                                    <div className="grid grid-cols-[84px_auto]">
                                        <BigStepper stepNumber={0} top={5}/>
                                        <ParsePDF
                                            assignment_id={assignment_id}
                                            setUpdateTrigger={setUpdateTrigger}
                                            isPublished={assignment.is_assignment_published}
                                            setPullQuestions={setPullQuestions}
                                            setEdited={setEdited}
                                            setSolRecent={setSolRecent}
                                            isEssay={assignment.is_essay}
                                        />
                                    </div>
                                    <BigStepperLine/>
                                </>
                                <div className="grid grid-cols-[84px_auto]">
                                    <BigStepper stepNumber={1} top={5}/>
                                    <TConstructor
                                        assignment_id={assignment_id}
                                        updateTrigger={updateTrigger}
                                        setUpdateTrigger={setUpdateTrigger}
                                        pullQuestions={pullQuestions}
                                        setPullQuestions={setPullQuestions}
                                        is_essay={assignment.is_essay}
                                        is_published={assignment.is_assignment_published}
                                        is_exam={assignment.is_exam}
                                        criteria={essayCriteria}
                                        setCriteria={setEssayCriteria}
                                        instructions={essayInstructions}
                                        setInstructions={setEssayInstructions}
                                        setEdited={setEdited}
                                        edited={edited}
                                        showRubric={showRubric}
                                        setShowRubric={setShowRubric}
                                        setSolRecent={setSolRecent}
                                        solRecent={solRecent}
                                        essayGradingInstructions={essayGradingInstructions}
                                        setEssayGradingInstructions={setEssayGradingInstructions}
                                        setGenerated={setGenerated}
                                        generated={generated}
                                    />
                                </div>
                                <BigStepperLine/>
                                {!assignment.is_essay && (
                                    <div className="grid grid-cols-[84px_auto] gap-4">
                                        <BigStepper stepNumber={2} top={0}/>
                                        <AnswerUpload
                                            assignment_id={assignment_id}
                                            setUpdateTrigger={setUpdateTrigger}
                                            isPublished={assignment.is_assignment_published}
                                            setPullQuestions={setPullQuestions}
                                            setEdited={setEdited}
                                            setSolRecent={setSolRecent}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="HomePageHeader">
                    <div id="Main-container" className="flex flex-row">
                        <div id="Left-Sidebar" className="fixed">
                            <SideBar activePage="Home"/>
                        </div>
                        <div id="Right-content" className="flex ml-side-bar-size pr-[30px] w-full">
                            <TopBar
                                assignment_in={assignment}
                                submissions_in={submissions}
                                setUpdateTrigger={setUpdateTrigger}
                                essayCriteria={essayCriteria}
                                essayInstructions={essayInstructions}
                                setPullQuestions={setPullQuestions}
                                edited={edited}
                                essayGradingInstructions={essayGradingInstructions}
                                showRubric={showRubric}
                                topErrorMessage={topErrorMessage}
                                setTopErrorMessage={setTopErrorMessage}
                                section={section}
                                setSection={setSection}
                                generated={generated}
                            />

                            <div
                                className={`flex flex-col mb-32 w-full ml-[30px] ${topErrorMessage ? 'mt-44' : 'mt-36'}`}
                            >
                                {assignment.is_exam && (
                                    <>
                                        <div className="grid grid-cols-[84px_auto]">
                                            <BigStepper stepNumber={3} top={0}/>
                                            <RenderBatch assignment_id={assignment_id} updateTrigger={updateTrigger}/>
                                        </div>
                                        <BigStepperLine/>
                                        <div className="grid grid-cols-[84px_auto]">
                                            <BigStepper stepNumber={4} top={0}/>
                                            <ExamUpload
                                                assignment_id={assignment_id}
                                                setUpdateTrigger={setUpdateTrigger}
                                            />
                                        </div>
                                        <BigStepperLine/>
                                    </>
                                )}

                                <div ref={tGradeRef} className="grid grid-cols-[84px_auto]">
                                    <BigStepper stepNumber={assignment.is_exam ? 5 : 3} top={0} ht={'[50px]'}/>
                                    {reviewMode === 'Question' ? (
                                        <TQuestionGrade
                                            assignment_in={assignment}
                                            submissions_in={submissions}
                                            setUpdateTrigger={setUpdateTrigger}
                                            reviewMode={reviewMode}
                                            setReviewMode={handleSetReviewMode}
                                        />
                                    ) : (
                                        <TGrade
                                            reviewMode={reviewMode}
                                            isLoading={isSubmissionLoading}
                                            itemsPerPage={itemsPerPage}
                                            pageNumber={pageNumber}
                                            submissionsError={submissionsError}
                                            assignment={assignment}
                                            submissions={submissions}
                                            setUpdateTrigger={setUpdateTrigger}
                                            setReviewMode={handleSetReviewMode}
                                            updateSearchParams={updateSearchParams}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default HomePage;
