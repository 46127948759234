import debounce from 'lodash.debounce';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

const StudentSearchDropdown = ({
  search,
  pageNumber,
  students,
  currentStudentName,
  isLoading,
  threshold = 25,
  itemsPerPage,
  onStudentSelect,
  updateSearchParams
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const printValue = debounce(
    value =>
      updateSearchParams({
        search: value,
        pageNumber: 1
      }),
    500
  );

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const highlightMatch = (text, search) => {
    if (!search) {
      return text;
    }
    const parts = text.split(new RegExp(`(${search})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === search.toLowerCase() ? (
        <span key={index} className="bg-orange-200">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleSearchChange = ({ target }) => printValue(target.value);

  return (
    <div ref={dropdownRef} className="relative w-full">
      <div
        className="w-full border border-gray-300 rounded-lg p-3 cursor-pointer flex justify-between items-center
                           bg-white hover:border-gray-400 transition-colors duration-200"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-gray-700">{currentStudentName || 'Select student'}</span>
        <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'} text-gray-400`} />
      </div>

      {isOpen && (
        <div className="absolute w-full mt-2 bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-hidden">
          <div className="p-2 border-b border-gray-100">
            <input
              type="text"
              className="w-full p-2 rounded-md border border-gray-200 focus:outline-none
                                     focus:border-orange-200 focus:ring-1 focus:ring-orange-300
                                     placeholder-gray-500 text-gray-700"
              placeholder="Search by name or ID..."
              autoFocus
              style={{
                WebkitTapHighlightColor: 'transparent',
                WebkitAppearance: 'none',
                MozAppearance: 'none',
                appearance: 'none'
              }}
              onChange={handleSearchChange}
            />
          </div>

          <div className="max-h-[240px] overflow-y-auto">
            <InfiniteScroll
              hasMore={!!students?.nextOffset}
              initialLoad={false}
              loader={!!isLoading ? <h4>Loading...</h4> : null}
              loadMore={() => {
                if (!!students?.nextOffset && students?.nextOffset !== 0) {
                  updateSearchParams({ pageNumber: pageNumber + 1 });
                }
              }}
              useWindow={false}
              threshold={itemsPerPage}
            >
              {(students?.entities ?? []).map(student => (
                <div
                  key={`${student.id} + ${student.full_name} + ${Math.random()}`}
                  className="px-4 py-3 hover:bg-gray-50 cursor-pointer transition-colors duration-150
                                         border-b border-gray-100 last:border-b-0"
                  onClick={() => {
                    onStudentSelect(student);
                    setIsOpen(false);
                  }}
                >
                  <div className="flex justify-between items-center">
                    <span className="text-gray-700 font-medium">
                      {highlightMatch(student.full_name, search)}
                    </span>
                    <div>
                      <span className="text-gray-500 text-sm px-4">
                        Match Key:{' '}
                        {!!student.match_key
                          ? highlightMatch(student.match_key.toString(), search)
                          : 'N/A'}
                      </span>
                      <span className="text-gray-500 text-sm px-4">
                        ID: {highlightMatch(student.id.toString(), search)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentSearchDropdown;
