import React from 'react';
import {Link, useParams} from 'react-router-dom';
import SideBar from '../../components/SideBar';
import AddDropBig from '../../components/Teacher/AddDropBig.js';

const ClassSettings = () => {
    const {class_id} = useParams();

    return (
        <div className="flex">
            {/* Left Sidebar */}
            <div className="w-side-bar-size flex-shrink-0">
                <div className="fixed h-screen">
                    <SideBar activePage="Settings"/>
                </div>
            </div>

            {/* Main Content */}
            <div className="flex-1">
                {/* Navigation Trail */}
                <div className="flex items-center space-x-2 p-8 pb-4">
                    <Link to="/tclasses">
                        <div className="size-10 flex items-center justify-center bg-gray-50 rounded-full">
                            <img src="/assets/sidebar/menu-home.png" className="size-5" alt="Home"/>
                        </div>
                    </Link>
                    <Link to="/tclasses">
                        <button>
                            <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                        </button>
                    </Link>
                    <Link to="/tclasses" className="text-black text-lg font-medium">
                        My Classes
                    </Link>
                    <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                    <span className="text-black text-lg font-medium">
            Class Settings
          </span>
                </div>

                {/* Main Content Area */}
                <div className="px-8">
                    {/* Title */}
                    <div className="mb-8">
                        <h1 className="text-2xl font-bold">Student List</h1>
                        <p className="text-sm text-gray-600 mt-2">
                            Upload a CSV file with student information or add students manually
                        </p>
                    </div>

                    {/* AddDropBig Component */}
                    <div className="bg-white rounded-lg border border-gray-200 p-6">
                        <AddDropBig class_id={class_id}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClassSettings;