import { configureStore } from '@reduxjs/toolkit';
import teacherSlice from './teacherSlice';
import tpsetSlice from './tpsetSlice';
import userSlice from './userSlice';

const store = configureStore({
  reducer: {
    userReducer: userSlice,
    tpsetReducer: tpsetSlice,
    teacherReducer: teacherSlice
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(),
  devTools: process.env.NODE_ENV !== 'production'
});

export default store;
