import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { gradeApi } from '../api.js';

export const loadDraftData = createAsyncThunk(
  'tpset/loadDraftData',
  async ({ assignment_id, token }, thunkApi) => {
    try {
      const response = await gradeApi.get({
        path: `/api/assignment/${assignment_id}/problem-set/load-draft/`,
        config: {
          headers: { Authorization: `Bearer ${token}` }
        }
      });

      return await response.data;
    } catch (error) {
      console.log(error);
      return await response.data;
    }
  }
);

export const updateDraftData = createAsyncThunk(
  'tpset/updateDraftData',
  async ({ dataToSave, token, assignment_id, isUploading = false }, { rejectWithValue }) => {
    try {
      console.log(dataToSave);
      const response = await gradeApi.post({
        path: `/api/assignment/${assignment_id}/problem-set/save-draft/`,
        body: {
          ...dataToSave,
          edited: isUploading ? dataToSave.edited : true
        },
        config: { headers: { Authorization: `Bearer ${token}` } }
      });
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadQuestions = createAsyncThunk(
  'tpset/loadQuestions',
  async ({ assignment_id, token }, thunkApi) => {
    const response = await gradeApi.get({
      path: `/api/assignment/${assignment_id}/questions-frontend/`,
      config: {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    });

    thunkApi.dispatch(
      updateDraftData({
        token,
        assignment_id,
        dataToSave: {
          examPaper: response.data.questions
        }
      })
    );

    return await response.data;
  }
);

export const uploadQuestions = createAsyncThunk(
  'tpset/uploadQuestions',
  async ({ assignment_id, token, body }, thunkApi) => {
    try {
      await gradeApi.post({
        path: `/api/assignment/${assignment_id}/constructor/`,
        body,
        config: { headers: { Authorization: `Bearer ${token}` } }
      });

      const response = await gradeApi.get({
        path: `/api/assignment/${assignment_id}/build/?is_teacher=true`,
        config: { headers: { Authorization: `Bearer ${token}` } }
      });

      thunkApi.dispatch(
        updateDraftData({
          token,
          assignment_id,
          dataToSave: {
            teacherPdfUrl: response.data.url,
            created: true,
            edited: false
          },
          isUploading: true
        })
      );

      return await response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.data?.error || error.message || 'An error occurred. Please try again.'
      );
    }
  }
);

const tpsetSlice = createSlice({
  name: 'tpset',
  initialState: {
    draftData: {
      data: {},
      isLoading: false,
      errorMessage: ''
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDraftData.pending, state => {
      state.draftData.isLoading = true;
    });
    builder.addCase(loadDraftData.rejected, state => {
      console.log(state);
      state.draftData.isLoading = false;
    });
    builder.addCase(loadDraftData.fulfilled, (state, action) => {
      state.draftData.isLoading = false;
      state.draftData.data = action.payload;
    });
    builder.addCase(updateDraftData.pending, state => {
      state.draftData.isLoading = true;
    });
    builder.addCase(updateDraftData.rejected, (state, action) => {
      state.draftData.isLoading = false;
      state.draftData.errorMessage = action.payload.message;
    });
    builder.addCase(updateDraftData.fulfilled, (state, action) => {
      state.draftData.isLoading = false;
      state.draftData.data = action.payload;
    });
    builder.addCase(loadQuestions.pending, state => {
      state.draftData.isLoading = true;
    });
    builder.addCase(loadQuestions.rejected, state => {
      state.draftData.isLoading = false;
      state.draftData.data = {
        ...state.draftData.data,
        created: false
      };
    });
    builder.addCase(loadQuestions.fulfilled, (state, action) => {
      const { questions, teacher_url } = action.payload;
      state.draftData.isLoading = false;
      state.draftData.data = {
        ...state.draftData.data,
        examPaper: questions,
        teacherPdfUrl: teacher_url,
        created: questions.length !== 0
      };
    });
    builder.addCase(uploadQuestions.pending, state => {
      state.draftData.isLoading = true;
    });
    builder.addCase(uploadQuestions.rejected, (state, action) => {
      state.draftData.isLoading = false;
      state.draftData.errorData = action.error;
    });
    builder.addCase(uploadQuestions.fulfilled, (state, action) => {
      state.draftData.isLoading = false;
      state.draftData.data = {
        ...state.draftData.data,
        edited: false
      };
    });
  }
});

export default tpsetSlice.reducer;
