import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {ErrorMessage, FieldArray, useFormikContext} from 'formik';
import {isString} from 'lodash';
import React from 'react';
import {TSubQuestionNew} from './TSubQuestionNew';

export const TSubQuestionColumnNew = ({
                                          question,
                                          subQuestions,
                                          isPublished,
                                          fieldName,
                                          disabledStyle,
                                          setError,
                                          setViewedQuestions,
                                          viewedQuestions,
                                          copiedRubricItems,
                                          onCopyRubricItems,
                                          onPasteRubricItems
                                      }) => {
    const {errors, setFieldValue, submitForm} = useFormikContext();

    const updateSubQuestionIds = (subQuestions) => {
        return subQuestions.map((sq, index) => ({
            ...sq,
            id: index + 1
        }));
    };

    return (
        <div>
            <SortableContext items={subQuestions} strategy={verticalListSortingStrategy}>
                {
                    <FieldArray name={fieldName}>
                        {({remove, push}) => (
                            <div>
                                {subQuestions.map((subQuestion, index) => (
                                    <TSubQuestionNew
                                        key={`${subQuestion.id}-${index}`}
                                        index={index}
                                        question={question}
                                        subQuestion={subQuestion}
                                        isPublished={isPublished}
                                        fieldName={fieldName}
                                        deleteSubQuestion={() => {
                                            remove(index);
                                            const updatedSubQuestions = updateSubQuestionIds(
                                                subQuestions.filter((_, i) => i !== index)
                                            );
                                            setFieldValue(fieldName, updatedSubQuestions);
                                            submitForm(); // Trigger form submission to update state
                                        }}
                                        disabledStyle={disabledStyle}
                                        setError={setError}
                                        viewedQuestions={viewedQuestions}
                                        setViewedQuestions={setViewedQuestions}
                                        copiedRubricItems={copiedRubricItems}
                                        onCopyRubricItems={onCopyRubricItems}
                                        onPasteRubricItems={onPasteRubricItems}
                                    />
                                ))}

                                <div className="flex justify-between flex-row-reverse items-center">
                                    <span className="text-red-800 text-xs">
                                        <ErrorMessage name={fieldName}>
                                            {msg => {
                                                if (isString(msg)) {
                                                    return msg;
                                                } else {
                                                    msg?.map(obj => {
                                                        if (!obj) {
                                                            return null;
                                                        }

                                                        if ('mcqOptions' in obj && isString(obj['mcqOptions'])) {
                                                            return obj['mcqOptions'];
                                                        }

                                                        if ('rubricItems' in obj && isString(obj['rubricItems'])) {
                                                            return obj['rubricItems'];
                                                        }

                                                        return null;
                                                    });

                                                    return null;
                                                }
                                            }}
                                        </ErrorMessage>
                                    </span>
                                    <button
                                        type="button"
                                        disabled={isPublished}
                                        className="less-sexy-button h-[38px]"
                                        onClick={() => {
                                            const newSubQuestionId = subQuestions.length + 1;
                                            const newSubQuestion = {
                                                id: newSubQuestionId,
                                                questionType: 'freeform',
                                                question: '',
                                                questionSizing: 30,
                                                solution: '',
                                                rubricItems: [
                                                    {descriptor: 'Fully correct', value: '2'},
                                                    {descriptor: 'Partially correct', value: '1'},
                                                    {descriptor: 'Incorrect or no work', value: '0'}
                                                ],
                                                mcqOptions: [
                                                    {option: 'A', text: 'Option A', isCorrect: true},
                                                    {option: 'B', text: 'Option B', isCorrect: false}
                                                ],
                                                image: null,
                                                imageSize: 50,
                                                weight: 1
                                            };
                                            const updatedSubQuestions = updateSubQuestionIds([...subQuestions, newSubQuestion]);
                                            setFieldValue(fieldName, updatedSubQuestions);
                                            setViewedQuestions(
                                                prev =>
                                                    new Map([
                                                        ...prev,
                                                        [question.id, [...(prev.get(question.id) || []), newSubQuestionId]]
                                                    ])
                                            );
                                            setTimeout(async () => {
                                                await submitForm();
                                            }, 500);
                                        }}
                                    >
                                        Add Sub-question
                                    </button>
                                </div>
                            </div>
                        )}
                    </FieldArray>
                }
            </SortableContext>
        </div>
    );
};