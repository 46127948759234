import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import '../App.css';
import Forgot from '../components/Forgot';
import SignIn from '../components/SignIn';
import SignUp from '../components/SignUp';
import Terms from '../components/Terms';

import { useAuth } from '../auth/AuthContext';

function Login() {
    const [pageToggle, setPageToggle] = useState('sign-in');
    const [backToggle, setBackToggle] = useState('');
    const { user, logout } = useAuth();

    useEffect(() => {
        const sessionToken = Cookies.get('session_token');
        const userId = Cookies.get('user_id');

        if (sessionToken && userId) {
            logout();
        }
    }, []);

    const GradeWizHeader = () => {
        return (
            <div className="">
                <div className="flex items-center space-x-2 pb-3">
                    <div className="size-12 flex items-center justify-center">
                        <img src="/gradewiz_logo.png" alt="GradeWiz Icon" className="size-12" />
                    </div>
                    <span className="text-minilg font-medium text-gray-950">GradeWiz</span>
                </div>
            </div>
        );
    };
    return (
        <div className="h-screen">
            <div className="w-full">
                <div className="page-wrapper">
                    <div
                        data-animation="default"
                        className="navbar-component w-nav"
                        data-easing2="ease"
                        fs-scrolldisable-element="smart-nav"
                        data-easing="ease"
                        data-collapse="medium"
                        data-w-id="2bbb12c9-9300-15f1-6549-3c38dcff06fb"
                        role="banner"
                        data-duration="400"
                    >
                        <div className="navbar-container">
                            <a
                                href="marketing/index.html"
                                aria-current="page"
                                className="navbar-logo-link w-nav-brand w--current"
                            >

                                <div className="flex items-center space-x-2 pb-3">
                                    <div className="size-12 flex items-center justify-center">
                                        <img src="/gradewiz_logo.png" alt="GradeWiz Icon" className="size-12" />
                                    </div>
                                    <span className="text-minilg font-medium text-gray-950">GradeWiz</span>
                                </div>
                            </a>

                            <div className="navbar-menu-button w-nav-button">
                                <div className="menu-icon">
                                    <div className="menu-icon-line-top"></div>
                                    <div className="menu-icon-line-middle">
                                        <div className="menu-icon-line-middle-inner"></div>
                                    </div>
                                    <div className="menu-icon-line-bottom"></div>
                                </div>
                            </div>
                            <div className="navbar-menu-desktop">
                                <a href="marketing/index.html#contact" className="navbar-link w-nav-link">
                                    Contact
                                </a>
                                <a href="/login" className="navbar-link w-nav-link">
                                    Login
                                </a>
                                <div className="w-layout-vflex flex-block-2">
                                    <a
                                        href="https://calendly.com/gradewiz/pilot?month=2024-02"
                                        className="button is-small w-button"
                                    >
                                        Schedule a Meeting
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`bg-backgray h-screen w-screen pt-14 flex flex-col justify-between ${pageToggle === 'create-account' || pageToggle === 'terms' ? 'pt-5' : 'pt-14'}`}
            >
                <div
                    className={`flex items-center justify-center w-[325px] mx-auto p-6 sexy-border ${pageToggle === 'create-account' || pageToggle === 'terms' ? 'h-[580px]' : 'h-[485px]'}`}
                >
                    <div
                        className={`absolute w-[350px] mx-auto p-6 sexy-border bg-white mt-6 flex flex-col justify-between ${pageToggle === 'create-account' || pageToggle === 'terms' ? 'h-[580px]' : 'h-[485px]'}`}
                    >
                        {pageToggle == 'sign-in' && (
                            <SignIn setPageToggle={setPageToggle} GradeWizHeader={GradeWizHeader}></SignIn>
                        )}
                        {pageToggle == 'create-account' && (
                            <SignUp
                                setPageToggle={setPageToggle}
                                pageToggle={pageToggle}
                                setBackToggle={setBackToggle}
                                GradeWizHeader={GradeWizHeader}
                            ></SignUp>
                        )}
                        {pageToggle == 'forgot-password' && (
                            <Forgot
                                setPageToggle={setPageToggle}
                                setB
                                ackToggle={setBackToggle}
                                backToggle={backToggle}
                                GradeWizHeader={GradeWizHeader}
                            ></Forgot>
                        )}
                        {pageToggle == 'terms' && (
                            <Terms
                                setPageToggle={setPageToggle}
                                backToggle={backToggle}
                                GradeWizHeader={GradeWizHeader}
                            ></Terms>
                        )}
                    </div>
                </div>
                <div className="flex items-center justify-center space-x-3 text-gray-500 text-smallish mb-28">
                    <button
                        onClick={() => {
                            setBackToggle(pageToggle);
                            setPageToggle('terms');
                        }}
                    >
                        Terms of Use
                    </button>
                    <div> |</div>
                    <button
                        onClick={() => {
                            setBackToggle(pageToggle);
                            setPageToggle('terms');
                        }}
                    >
                        Privacy Policy
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Login;